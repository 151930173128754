import React, { useRef } from "react";
import { Img, Text } from "components";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


const Home = () => {
  const options = {
    rewind: true,
    autoPlay: true,
    perPage: 3,
    gap: 10,
  };
  const videos = ["c7Tah4_O1QU", "Mt61wyAaE_Q", "gsSFeiNAyJ8", "ukDd2hwcTq8", "qC5QSd1Nn_s", "FqXnQ3wgQqg", "o_DT3UqBh88", "fYn5ljPKKzc", "HqxY3d1LvPk", "NmL1BhEG47E", "Cs5gJ1gArp8", "RRuIYKJle4c"];
  const videoDesc = ["Bedtime Stories for Kids | Little Whale Search For Mommy", "Bedtime Stories for Kids | The Brave Little Whale's Ocean Adventure", "Bedtime Stories for Kids | Little Whale and the Magical Bubbles", "Bedtime Stories for Kids | Little Whale Undersea Music Adventure", "Bedtime Stories for Kids | Little Whale, Guardian of the Ocean", "Bedtime Stories for Kids | Little Whale's Ocean Rescue Team", "Bedtime Stories for Kids | Little Whale and Uncle Shark's Teeth", "Bedtime Stories for Kids | Little Whale and the Lightning Sailfish", "Bedtime Stories for Kids | Little Whale and the Secret of Starfish", "Bedtime Stories for Kids | Something About My Giraffe Friend", "Bedtime Stories for Kids | Little Whale, Where Do You Come From?", "Bedtime Stories for Kids | Little Whale and the Incredible Ink"];
  // const thumbnails = ["LWSFM_Cover.png", "BLWOA_Cover.png", "LWMB_Cover.png"];

  const handleYoutubeClick = event => {
    const url = "https://www.youtube.com/@pikabookstories";
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleFacebookClick = event => {
    const url = "https://www.facebook.com/pikabookstories";
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  const goToVideo = (id, e) => {
    const url = "https://www.youtube.com/watch?v=" + id;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <div className="bg-blue_gray-800 flex flex-col font-kidzone gap-5 items-center justify-start mx-auto pb-5 w-[1440px] sm:w-full md:w-full">
        <div className="flex flex-col h-full md:h-auto items-center justify-start w-full">
          <Img
            className="md:h-auto sm:h-auto object-cover h-full w-auto"
            src="images/img_background1.png"
            alt="backgroundOne"
          />
        </div>
        <div className="flex flex-col items-center justify-center p-2.5 w-full">
          <h1>
            <Text
              className="sm:text-[25px] md:text-[41px] text-[45px] text-white-A700 text-center w-auto"
              size="txtKidZone45"
            >
              Visit Our Videos on YouTube
            </Text>
          </h1>
          <div className="flex flex-col items-center justify-center p-2.5 w-full">
            <Splide options={options} aria-label="PIKABOOK Stories - Kids YouTube Videos">
              {videos.reverse().map((id, index) => (

                <SplideSlide onClick={(e) => goToVideo(id, e)} key={id}>
                  {/* 
                  <img src={'images/' + thumbnails[index]}
                    alt={"Watch " + videoDesc[index] + " on PIKABOOK Stories YouTube Channel"}
                  />  
                  */}
                  <img src={'https://i.ytimg.com/vi/' + videos[index] + '/maxresdefault.jpg'}
                    alt={"Watch " + videoDesc[index] + " on PIKABOOK Stories YouTube Channel"}
                  />

                </SplideSlide >
              ))}
            </Splide>

          </div>
        </div>
        <div className="flex flex-col items-center justify-center p-2.5 w-full">
          <Text
            className="sm:text-[25px] md:text-[41px] text-[45px] text-white-A700 text-center w-auto"
            size="txtKidZone45"
          >
            About
          </Text>
        </div>
        <div className="flex flex-col items-center justify-center md:px-10 px-20 sm:px-5 w-full">
          <Text
            className="text-2xl md:text-[20px] text-center text-white-A700 sm:text-xl"
            size="txtKidZone24"
          >
            <>
              Welcome to Pikabook Stories, with animated bedtime stories for kids we dedicated to create a captivating and educational experience for children.
              <br />
              <br />
              Crafted by a creative father, dedicated to creating animated bedtime stories that take children on enchanting adventures.
              <br />
              <br />
              From my 3-year-old daughter&#39;s first babble, she insisted on hearing storybooks every night. She eagerly follows along, mimicking my narrative and pointing at characters, describing their actions and sounds.
              <br />
              <br />
              This inspired me to create captivating YouTube animations. Cartoons lack the depth of written words, so I aim to create &#34;bedtime stories that move&#34; - narrated with a delightful voice and charming animation - capturing children&#39;s attention and fostering their growth. Through these storybooks, children learn vocabulary, explore rhymes, and discover positive values.
              <br />
              <br />
              <a style={{ color: "#ff8345" }} href="https://www.youtube.com/channel/UCIVpTvzPWBMuV7rK9Yp7nzQ?sub_confirmation=1" target="_blank" rel="noopener noreferrer">Subscribe to our channel</a> and let the enchantment of Pikabook Stroies
              inspire your child&#39;s love for reading and learning!
            </>
          </Text>
        </div>
        <div className="flex flex-col font-montserrat gap-5 items-center pt-[50px] md:px-10 px-20 sm:px-5 w-full">
          <div className="flex gap-[5px] items-center justify-end max-w-7xl mx-auto py-2.5 w-full">
            <Img
              className="h-9 w-9 "
              src="images/img_youtube.svg"
              alt="youtube"
              onClick={handleYoutubeClick}
            />
            <Img
              className="h-9 w-9"
              src="images/img_facebook.svg"
              alt="facebook"
              onClick={handleFacebookClick}
            />
            {/* <Img className="h-9 w-9" src="images/img_ig.svg" alt="instagram" onClick={handleClick}/> */}
          </div>
          <Text
            className="text-[15px] text-center text-white-A700 w-full"
            size="txtMontserratRomanRegular15"
          >
            Copyright © 2024 arzai.studio - All Rights Reserved.
          </Text>
        </div>
      </div>

    </>
  );
};



// export default MainPage;
export default Home;
